export const appointmentBook = {
  nationalId: "",
  nationalIdUrl: "",
  insurance: {
    provider: "",
    membershipNo: "",
  },
  symptoms: [],
  surgeries: [],
  medicalConditions: [],
  patientHeight: "100",
  firstName: "",
  reasonForVisit: "",
  notesToDoctor: "Test",
  weightMeasurement: "kg",
  noOfBooking: 1,
  bookingFrom: "AppointmentBooking",
  reminderNumber: "",
  appointmentFor: "",
  reports: "",
  blockId: "88620484",
  basicFees: "100.00",
  surgerydetails: [],
  referenceId: "123456",
  allergies: [],
  gender: "Male",
  appointmentDate: "",
  emergencyname: "",
  relationship: "",
  patientWeight: "200",
  dob: "31/May/2003",
  heightMeasurement: "cm",
  medications: [],
  emergencyrelation: "",
  dentalInfo: "[]",
  bookingType: "Book",
  fees: "INR 100.00",
  hospitalid: "123456",
  typeofconsultation: "",
  doctorId: "",
  emergencyphone: "",
  status: "",
  modeOfConsultation: "Video",
  duration: "15",
  appointmentTime: "",
  lastName: "",
  gstamount: "",
  duration: "",
  reportsArray: [],
  reasonForVisitIndex: null,
  nationalIdfile: "",
  routing: false,
  countryCode: "91",
  alternativeCode: "91",
  emergencyNoCode: "91",
};
