export const LOGIN_INIT = "LOGIN_INIT";
export const SPECIALITY_LISTING = "SPECIALITY_LISTING";
export const DOCTOR_DETAIL = "DOCTOR_DETAIL";
export const REMOVE_DOCTOR_DETAIL = "REMOVE_DOCTOR_DETAIL";
export const APPOINTMENT_SLOT = "APPOINTMENT_SLOT";
export const APPOINTMENT_MORE_SLOT = "APPOINTMENT_MORE_SLOT";
export const TREATMENT_LISTING = "TREATMENT_LISTING";
export const BOOK_APPOINMENT_QUESTIONARE = "BOOK_APPOINMENT_QUESTIONARE";
export const DOCTOR_LISTING = "DOCTOR_LISTING";
export const GENERATE_OTP = "GENERATE_OTP";
export const STORE_APPOINMENT_FORM_DATA = "STORE_APPOINMENT_FORM_DATA";
export const REASONFOR_VISIT = "REASONFOR_VISIT";
export const TYPE_OF_APPOINMENT = "TYPE_OF_APPOINMENT";
export const INSURANCE_LIST = "INSURANCE_LIST";
export const BOOKING_RELATED_DATA = "BOOKING_RELATED_DATA";
export const ADD_FAMILY = "ADD_FAMILY";
export const EDIT_FAMILY = "EDIT_FAMILY";
export const FETCH_CLIENTDETAILS = "FETCH_CLIENTDETAILS";
export const BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION";
export const BOOKING_UPDATE_CONFIRMATION = "BOOKING_UPDATE_CONFIRMATION";
export const LOGIN_SUCCESS_ACTION = "LOGIN_SUCCESS_ACTION";
export const LOG_OUT_ACTION = "LOG_OUT_ACTION";
export const SIGNUP_SUCCESS_ACTION = "SIGNUP_SUCCESS_ACTION";
export const FETCH_FAMILY_MEMBERS = "FETCH_FAMILY_MEMBERS";
export const FETCH_PATIENT_MEDICAL_DATA = "FETCH_PATIENT_MEDICAL_DATA";
export const FETCH_UPLOADED_DATA = "FETCH_UPLOADED_DATA";
export const REQUEST_BOOKING_CONFIRM = "REQUEST_BOOKING_CONFIRM";
export const GOTO_DASHBOARD = "GOTO_DASHBOARD";
export const FETCH_PATIENT_MEDICAL_DATA_INIT_ACTION =
  "FETCH_PATIENT_MEDICAL_DATA_INIT_ACTION";
export const FETCH_PATIENT_MEDICAL_DATA_FAIL_ACTION =
  "FETCH_PATIENT_MEDICAL_DATA_FAIL_ACTION";
export const RESET_BOOKING = 'RESET_BOOKING';
export const UPDATE_LOGIN = 'UPDATE_LOGIN';
